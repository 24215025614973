import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AppConfigProps, defaultAppConfig } from './defaultAppConfig';
import { NationalsProps, National, NationalConfigProps } from './types';
import { getNationalConfig, getNationals } from '@rugby-au/services/src/rx/config';

const AppConfigContext = createContext<{
  appConfig: AppConfigProps;
}>({
  appConfig: defaultAppConfig,
});

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends object ? RecursivePartial<T[P]> : T[P];
};

export const AppConfigProvider = ({
  appConfig: providedAppConfig,
  defaultNationalId,
  children,
}: {
  appConfig: RecursivePartial<AppConfigProps>;
  children: ReactNode;
  defaultNationalId?: National | null;
}) => {
  const [multiFormLayout, setMultiFormLayout] = useState(null);
  const [nationalId, setNationalId] = useState<National | null>(defaultNationalId ?? null);
  const [nationals, setNationals] = useState<NationalsProps[]>();
  const [nationalConfig, setNationalConfig] = useState<NationalConfigProps>();
  const [notification, setNotification] = useState({ severity: '', message: '' });

  useEffect(() => {
    const loadNationals = async () => {
      setNationals(await getNationals());
    };
    loadNationals();
  }, []);

  useEffect(() => {
    const handleNationalConfig = async () => {
      if (nationalId) {
        const _nationalConfig = await getNationalConfig(nationalId);
        setNationalConfig(_nationalConfig);
      }
    };
    handleNationalConfig();
  }, [nationalId]);

  const value = useMemo(
    () => ({
      appConfig: {
        ...defaultAppConfig,
        multiFormLayout,
        setMultiFormLayout,
        nationalId,
        setNationalId,
        notification,
        setNotification,
        ...providedAppConfig,
        nationalConfig,
        nationals,
      },
    }),
    [multiFormLayout, nationalId, notification, providedAppConfig, nationalConfig, nationals],
  );
  return (
    <AppConfigContext.Provider
      // @ts-ignore
      value={value}>
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig = () => {
  const { appConfig } = useContext(AppConfigContext);

  return appConfig;
};
