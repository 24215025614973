import { CartProps } from '@rugby-au/services';
import { WebHeaderProps } from '@rugby-au/web-header';
import { ErrorMessage, NationalsProps, National, NationalConfigProps } from './types';

//TODO add props when we have a use case for them
export type AppConfigProps = {
  /** RX nationalId RAU|USA */
  nationalId: National | null;
  setNationalId: (nationalId: National) => void;
  notification?: ErrorMessage;
  setNotification: (error: ErrorMessage) => void;
  header?: WebHeaderProps;
  setHeader?: (nationalId: WebHeaderProps) => void;
  setNavigation?: (path: string) => void;
  appName: string;
  cart: CartProps;
  setCart: (cart: CartProps) => void;
  multiFormLayout: string | null;
  setMultiFormLayout: (value: string | null) => void;
  isLogged: boolean;
  setIsLogged: (value: boolean) => void;
  nationalConfig: NationalConfigProps | null;
  nationals?: NationalsProps[];
};

export const defaultAppConfig = {
  nationalId: null,
  nationalConfig: null,
  setNationalId: () => {},
  appName: 'RX',
  cart: { items: [], meta: {} },
  setCart: () => {},
  setNotification: () => {},
  multiFormLayout: null,
  setMultiFormLayout: () => {},
  isLogged: false,
  setIsLogged: () => {},
};
