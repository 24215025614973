import { useTheme } from '@rugby-au/theme';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { View, ViewStyle } from 'react-native';
import { Text } from '@rugby-au/commons';

import { getPickerInputStyles /*getPickerInputModalStyles*/ } from './styles';
import { PickerInput } from './PickerInput';
import { autoValidateIn } from '../utils';
import { FieldRefProps } from '../types';

export type Items = {
  /** can be label or emoji or both*/
  label?: any;
  emoji?: any;
  /** if emoji is provided it must have img, so when Emoji is not supported will show the image in BASE64*/
  img?: any;
  /** Value */
  value: any;
  /** additioanl data*/
  data?: any;
};
export interface PickerInputFieldProps {
  /** Label */
  label?: string;
  /** Text value */
  value: any;
  /** Text empty */
  emptyValue?: any;
  /** list of items*/
  items: Items[]; //Array<Items>;
  /** is disabled */
  disabled?: boolean;
  /** invoke in on change */
  onChangeField?: (value: Items | undefined) => void;
  /** Error message */
  required?: boolean;
  /** auto validate */
  autoValidate?: boolean;
  /** Info message */
  info?: string;
  /** auto complete */
  //autocomplete?: string;
  labelStyles?: any;
  /** Field Styles */
  fieldStyles?: any;
  /** Editable */
  editable: boolean;
  /** Dialog */
  dialog?: boolean;
  /**2nd render property */
  concatRenderProp?: string;
  /**fieldRef */
  fieldRef: FieldRefProps;
  containerStyles?: ViewStyle;
  placeholder?: string;
}

export const PickerInputField = ({
  label,
  value,
  emptyValue = '',
  disabled = false,
  onChangeField,
  required = false,
  autoValidate = false,
  items,
  labelStyles,
  fieldStyles = {},
  editable = true,
  fieldRef,
  placeholder = 'Please select an option',
  ...props
}: PickerInputFieldProps) => {
  // Keeping a seperate state for the value to avoid react rendering delays
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [itemValue, setItemValue] = useState(
    items?.find((i: any) => (typeof i.value === 'object' ? JSON.stringify(i.value) === JSON.stringify(inputValue) : i.value?.toLowerCase() === inputValue?.toLowerCase())),
  );
  //const [isValid, setIsValid] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { colors, radius, spacing, typography } = useTheme();

  // On init assign default input value to state
  useEffect(() => {
    if (inputValue !== value && !hasChanged) {
      setInputValue(value);
      setItemValue(items.find((i: any) => i?.value === value));
    }
  }, [inputValue, items, value, hasChanged]);

  const validate = useCallback(() => {
    if (required && !inputValue) {
      //setIsValid(false);
      setIsError(true);
      setError('Field required');
      return false;
    }
    //setIsValid(true);
    setIsError(false);
    setError('');
    return true;
  }, [inputValue, required]);

  useEffect(() => {
    if (autoValidate) {
      if (hasChanged) {
        timerRef.current = setTimeout(() => {
          validate();
        }, autoValidateIn);
      }
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }
  }, [autoValidate, hasChanged, inputValue, validate]);

  fieldRef.current = {
    validate: validate,
    value: inputValue,
  };

  const handleOnChange = (item: string) => {
    let _itemValue = items?.find((i: any) => {
      return i.value === item;
    });
    setItemValue(_itemValue);
    setInputValue(item);
    setHasChanged(true);
    // setModalVisible(false);
    onChangeField && onChangeField(_itemValue);
  };

  // const modalStyles = getPickerInputModalStyles();
  const { styles } = getPickerInputStyles({ isError, /*isValid,*/ disabled, colors, radius, spacing, typography });

  return (
    <View>
      {!!label && (
        <Text style={{ ...styles.label, ...labelStyles }}>
          {label} {required && '*'}
        </Text>
      )}
      <PickerInput
        styles={styles}
        inputValue={inputValue}
        handleOnChange={handleOnChange}
        items={items}
        fieldStyles={{ ...styles.item, ...fieldStyles }}
        editable={editable}
        label={label}
        emptyValue={emptyValue}
        itemValue={itemValue}
        iconColor={colors.neutralDark}
        disabled={disabled}
        placeholder={placeholder}
        {...props}
      />
      {!!error && <Text style={styles.error}>{error}</Text>}
    </View>
  );
};
