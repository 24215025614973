import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Head from 'next/head';

import { AppConfigProvider } from '@rugby-au/app-config';
import { getUser } from '@rugby-au/services';
import { getTheme, ThemeProvider } from '@rugby-au/theme';
import { authHeader, WebHeaderProps } from '@rugby-au/web-header';
import { NotificationBar } from '@rugby-au/notification-bar';

import '../styles/globals.css';
import 'react-datepicker/dist/react-datepicker.css';

import { siteColors } from '../configs/siteTheme';
import { setCredentials } from '@rugby-au/services/src/rx/auth/user';
try {
  const avoidConsoleLog: any = ['Warning: '];
  const originalError = console.error;
  console.error = function () {
    const avoidFound = avoidConsoleLog.find(function (e: string) {
      return JSON.stringify(arguments).includes(e);
    });
    if (!avoidFound || avoidFound?.length <= 0) {
      originalError.apply(this, arguments as any);
    }
  };
} catch {
  // console.log('logger error', err);
}
export default function App({ Component, pageProps }: AppProps) {
  const theme = getTheme({ siteColors });
  //Header config for app
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);
  const [header, setHeader] = useState<WebHeaderProps>({});

  useEffect(() => {
    const handleHeader = () => {
      const _header = {
        ...authHeader,
        primaryNav: {
          ...authHeader.primaryNav,
          navItems: [],
        },
      };

      setHeader(_header as WebHeaderProps);
    };

    const handleLoggedIn = async () => {
      const user = await getUser();
      if (user?.userId) {
        setIsLogged(true);
      }
    };

    handleLoggedIn();
    handleHeader();
  }, []);

  const router = useRouter();
  useEffect(() => {
    //console.log("🚀 ~ file: _app.tsx:52 ~ useEffect ~ isLogged:", isLogged, router.pathname, router.asPath, router)
    if (isLogged === false && !['/login', '/logout'].includes(router.pathname)) {
      setCredentials(undefined);
      router.push(`${router.asPath}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    <AppConfigProvider
      appConfig={{
        header,
        setHeader,
        setNavigation: (path: string) => {
          router.push(path);
        },
        appName: 'rugbyxplorer-auth',
        isLogged,
        setIsLogged,
      }}
      defaultNationalId={null}>
      <ThemeProvider theme={theme}>
        <Head>
          <title>Rugby Xplorer Auth</title>
        </Head>
        <NotificationBar />
        <Component {...pageProps} />
      </ThemeProvider>
    </AppConfigProvider>
  );
}
